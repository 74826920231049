<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        En 2001, Nescafe a lancé une cannette du café auto chauffante qui s’appelait "Hot When You
        Want". Pour faire chauffer le café, il a fallu appuyer sur un bouton qui a mélangé les
        produits chimiques chauffants dans une coquille à l’extérieur de la cannette qui contenait
        le café. Une seule étape a fait mélanger l’oxyde de calcium avec de l’eau afin de produire
        l’hydroxyde de calcium et la chaleur par la réaction ci-dessous:
      </p>

      <p class="mb-5 pl-4">
        <chemical-latex content="CaO(s) + H2O(l) -> Ca(OH)2(aq)" />
        <stemble-latex content="$\qquad\Delta\text{H}=-82.0\,\text{kJ/mol}$" />
      </p>

      <p class="mb-2">
        a) En considérant que la chaleur massique du café est égale à celle de l’eau,
        <stemble-latex content="$4.184\,\text{J}\,\text{g}^{-1}\,\text{K,}$" />
        calculez l’énergie nécessaire (en J) pour réchauffer
        <number-value :value="volume" unit="\text{cm}^3" />
        du café par
        <number-value :value="tempChange" unit="^\circ\text{C.}" />
      </p>

      <calculation-input
        v-model="inputs.heatRequired"
        prepend-text="$\text{q:}$"
        append-text="$\text{J}$"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) Utilisez la valeur déterminer dans la partie a) pour calculer la masse minimale du CaO
        nécessaire dans la coquille externe pour faire fonctionner la cannette comme spécifié.
      </p>

      <calculation-input
        v-model="inputs.minimumMass"
        prepend-text="$\text{Masse de CaO:}$"
        append-text="$\text{g}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import NumberValue from '@/tasks/components/NumberValue.vue';

export default {
  name: 'UOttawaPrelab2Q4Fr',
  components: {
    NumberValue,
    ChemicalLatex,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        heatRequired: null,
        minimumMass: null,
      },
    };
  },
  computed: {
    volume() {
      return this.taskState.getValueBySymbol('volume').content;
    },
    tempChange() {
      return this.taskState.getValueBySymbol('tempChange').content;
    },
  },
};
</script>
